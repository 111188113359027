'use client';

import { useEffect } from 'react';

type ErrorProps = {
	error: Error & { digest?: string };
	reset: () => void;
};
export default function Error({ error, reset }: ErrorProps) {
	useEffect(() => {
		console.error(error);
	}, [error]);

	return (
		<div>
			<p>There was a problem</p>
			<h1>{error.message || 'Something went wrong!'}</h1>
			<p>Please try again later or contact support if the problem persists.</p>
			<button onClick={() => reset()}>Try again</button>
		</div>
	);
}
